<!-- eslint-disable-next-line vue/valid-v-slot -->
<template>
  <div>
    <div v-if="initialLoading">
      <v-row>
        <v-col>
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <DatatableWrapper
      v-else
      :loading="feedbackLoading"
      :pagination="pagination"
      :title="title"
      clickable-row
    >
      <v-data-table
        :expanded.sync="latestExpand"
        :headers="feedbackHeader"
        :items="sessionFeedback"
        disable-sort
        mobile-breakpoint="0"
        hide-default-footer
        @click:row="
          $router.push({
            name: 'release-comment',
            params: {
              app_id: $route.params.app_id,
              release_id: $event?.release?.id,
              comment_id: $event.id,
            },
          })
        "
        single-expand
      >
        <template #item.id="{ item }">
          <span class="text-uppercase font-weight-bold">{{ item.id }}</span>
        </template>

        <template #item.user="{ item }">
          <user-avatar :user-detail="item.user"></user-avatar>
        </template>

        <template #item.attachments="{ item }">
          <flex-row gap="4px" justify="center">
            <v-icon>mdi-attachment</v-icon>
            <span>{{ item?.stats?.attachments ?? 0 | formatNumber }}</span>
          </flex-row>
        </template>

        <template #item.release="{ item }">
          <release-chip :release="item.release" />
        </template>

        <template #item.message="{ item }">
          <MaxText v-if="item.message" :text="item.message" max="150" />
          <div v-else>-</div>
        </template>

        <template #item.status="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                <v-icon :color="$options.STATUS[item.status].color">{{
                  $options.STATUS[item.status].icon
                }}</v-icon>
              </span>
            </template>
            <span>
              {{ $options.STATUS[item.status].title }}
            </span>
          </v-tooltip>
        </template>

        <template #item.created_at="{ item }">
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </template>
        <template #no-data>
          <no-data
            align-class="text-left"
            first-text="No Comments for This App... Yet!"
            second-text="<ul> <li><strong>Team</strong>: Team feedback about the app will appear here.</li> <li><strong>Guests</strong>: Shared the app with external testers? Their feedback shows up here. Remember, you can invite unlimited Guests for feedback in our app.</li> </ul>"
          ></no-data>
        </template>
      </v-data-table>
    </DatatableWrapper>

    <Modal
      v-model="singleFeedbackDetailModal"
      width="100%"
      @close="singleFeedbackDetailModal = false"
    >
      <template #card>
        <single-feedback-detail
          :feedback-detail="feedbackDetail"
          @close="singleFeedbackDetailModal = false"
          @update-attachment="updateAttachmentPage"
          @confirm-delete-attachment="onConfirmDeleteAttachment"
          @delete-dialog="openDeleteDialog"
          @update-feedback="openUpdateFeedback"
          @update-status="updateStatus"
          @open-flagging="openFlagging"
          @feedback-update-dialog="feedbackUpdateDialog"
        ></single-feedback-detail>
      </template>
    </Modal>

    <!-- openUpdateFeedbackForm -->
    <Modal
      v-model="openUpdateFeedbackForm"
      @close="openUpdateFeedbackForm = false"
    >
      <template #message>
        <v-row>
          <v-col class="mt-4 font-size-h3 bold-text" cols="12">
            Update feedback message
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="formValues.message"
              v-validate="'max:600'"
              auto-grow
              counter="600"
              filled
              hide-details="auto"
              label="Message"
              persistent-hint
              placeholder="Do you have any feedback for this release?"
              rows="4"
              v-bind="veeValidate('Message', '')"
            >
            </v-textarea>
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              :disabled="updateLoading"
              class="mr-2 text-transform-none"
              color="primary"
              text
              @click="openUpdateFeedbackForm = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="updateLoading"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              depressed
              @click="updateFeedback"
            >
              Update
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>

    <Modal
      v-model="openFlaggingConfirmModal"
      @close="openFlaggingConfirmModal = false"
    >
      <template #message>
        <v-row>
          <v-col class="mt-4 font-size-h3 bold-text" cols="12">
            Confirm Flagging
          </v-col>
          <v-col class="bodyFont" cols="12">
            Are you sure you want to flag this feedback?
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              :disabled="feedbackLoading"
              class="text-transform-none"
              color="primary"
              text
              @click="openFlaggingConfirmModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="feedbackLoading"
              class="text-transform-none"
              color="primary"
              depressed
              @click="updateStatus()"
            >
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  DELETE_FEEDBACK_ATTACHMENT,
  UPDATE_FEEDBACK_DETAIL,
  UPDATE_FEEDBACK_STATUS,
} from "@/store/releases/releases.module";
import SingleFeedbackDetail from "@/view/components/Feedback/SingleFeedbackDetail";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
import veeValidate from "@/mixins/veeValidate";
import { mapMutations } from "vuex";
import UserAvatar from "@/view/components/Common/UserAvatar";

export default {
  props: {
    title: {
      type: String,
    },
    releaseId: {
      type: String,
      default: "",
    },
  },
  components: { UserAvatar, SingleFeedbackDetail, DatatableWrapper },
  mixins: [veeValidate],
  STATUS: {
    1: {
      title: "Not Resolved",
      color: "orange",
      icon: "mdi-dots-horizontal-circle-outline",
      value: 1,
    },
    3: {
      title: "Flagged for review ",
      color: "red",
      icon: "mdi-flag-outline",
      value: 3,
    },
    2: {
      title: "Resolved",
      color: "green",
      icon: "mdi-check-circle-outline",
      value: 2,
    },
  },
  data() {
    return {
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      feedbackDetail: {},
      showFilter: true,
      singleFeedbackDetailModal: false,
      openFeedbackDeleteDialog: false,
      openFlaggingConfirmModal: false,
      openUpdateFeedbackForm: false,
      feedbackHeader: [
        {
          text: "Id",
          value: "id",
          width: 100,
        },
        {
          text: "User",
          value: "user",
          width: 150,
        },
        {
          text: "Attachments",
          value: "attachments",
          align: "center",
          width: 140,
        },
        {
          text: "Release",
          value: "release",
          width: 200,
        },
        {
          text: "Message",
          value: "message",
          width: 400,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          width: 100,
        },
        {
          text: "Date",
          value: "created_at",
          width: 0,
          cellClass: "no-wrap",
        },
        // {
        //   text: "Session",
        //   value: "session",
        //   width: 100,
        // },
        // {
        //   text: "User",
        //   value: "user",
        //   width: 250,
        // },
        // {
        //   text: "Attachments",
        //   value: "attachments",
        //   align: "center",
        //   width: 140,
        // },
        // {
        //   text: "Feedback",
        //   value: "feedback",
        //   width: 240,
        // },
        // {
        //   text: "Date",
        //   value: "created_at",
        //   width: 0,
        //   cellClass: "no-wrap",
        // },
        // {
        //   text: "",
        //   align: "center",
        //   sortable: false,
        //   width: 100,
        //   value: "navigate",
        // },
      ],
      sessionFeedback: [],
      feedbackLoading: false,
      latestExpand: [],
      formValues: {
        message: "",
        rating: "",
      },
      updateLoading: false,
      selectedId: "",
      selectedStatus: 0,
      selectedImageId: null,
      initialLoading: true,
      totalFeedbackCount: 0,
    };
  },
  computed: {
    lastId() {
      return this.sessionFeedback[this.sessionFeedback.length - 1]?.id ?? "";
    },
    appId() {
      return this.$route.params.app_id;
    },
  },
  watch: {
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });
      this.pagination.page = newPage;
      this.getSessionFeedback({ page: newPage, last_id: this.lastId });
    },
    totalFeedbackCount(newVal) {
      this.pagination.totalItems = newVal;
    },
  },
  created() {
    this.getSessionFeedback().then(() => {
      this.initialLoading = false;
    });
  },
  methods: {
    ...mapMutations({
      setTotalCommentCount: "setTotalCommentCount",
      setAllReleaseComment: "setAllReleaseComment",
    }),
    getSessionFeedback({ last_id = "", page = this.pagination.page } = {}) {
      this.feedbackLoading = true;
      const params = {
        page,
        releaseID: this.releaseId ?? "",
        lastId: this.pagination.page == 1 ? "" : last_id,
        shareId: "",
        external: false,
      };

      return this.$coreApi
        .get(
          `${this.apiRoutes.core.app.getCommentsByAppId(
            this.appId
          )}?${this.getQuery(params)}`
        )
        .then(({ data }) => {
          this.sessionFeedback = data?.data;
          this.totalFeedbackCount = data?.meta?.count;
          this.setTotalCommentCount(data?.meta?.count);
          this.setAllReleaseComment(data?.data);
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.feedbackLoading = false;
        });
    },
    feedbackUpdateDialog() {
      this.formValues.message = this.activeFeedback?.message;
      this.openUpdateFeedbackForm = true;
    },
    async updateFeedback() {
      if (await this.validateAllFields()) {
        if (this.updateLoading) return;
        this.updateLoading = true;
        const dataToPost = {
          app_id: this.$route.params.app_id,
          message: this.formValues.message,
          feedback_id: this.selectedFeedback.id,
        };
        this.$store
          .dispatch(UPDATE_FEEDBACK_DETAIL, dataToPost)
          .then((response) => {
            this.updateLoading = false;
            this.openUpdateFeedbackForm = false;
            this.activeFeedback.message = this.formValues.message;
            this.notifyUserMessage({ message: response.message });
          })
          .catch((err) => {
            this.updateLoading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    openFlagging(id, status) {
      this.selectedId = id;
      this.selectedStatus = status;
      this.openFlaggingConfirmModal = true;
    },
    updateStatus(feedbackId, status) {
      if (this.feedbackLoading) return;
      this.feedbackLoading = true;
      let payload = {
        app_id: this.$route.params.app_id,
        feedback_id: feedbackId || this.selectedId,
        status: status || this.selectedStatus,
      };
      this.$store
        .dispatch(UPDATE_FEEDBACK_STATUS, payload)
        .then(() => {
          this.feedbackLoading = false;
          if (this.selectedStatus == 3 && this.release.id) {
            this.updateLatestReleaseFeedbackCount({ id: this.release.id });
          }
          this.updateInstallFeedbackStatus({
            id: feedbackId || this.selectedId,
            status: status || this.selectedStatus,
          });

          this.openFlaggingConfirmModal = false;
          this.selectedId = "";
          this.selectedStatus = "";
        })
        .catch((err) => {
          this.feedbackLoading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openUpdateFeedback(detail) {
      this.selectedId = detail.id;
      this.formValues.message = detail.message;
      this.formValues.rating = detail.rating;
      this.openUpdateFeedbackForm = true;
    },
    openDeleteDialog(feedbackId) {
      this.selectedId = feedbackId;
      this.openFeedbackDeleteDialog = true;
    },
    onConfirmDeleteAttachment() {
      this.$store
        .dispatch(DELETE_FEEDBACK_ATTACHMENT, {
          app_id: this.$route.params.app_id,
          feedback_id: this.activeFeedback?.id,
          attachment_id: this.selectedImageId,
        })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.selectedImageId = null;
          this.fetchAttachments("fromDelete");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    updateAttachmentPage(pageIndex) {
      this.pagination.page = pageIndex;
    },
    handleNavigation(item) {
      this.$router.push({
        name: "session-feedback-detail",
        params: {
          app_id: item.app_id ?? this.appId,
          session_id: item.session_id,
          feedback_id: item.id,
        },
      });
    },
  },
};
</script>
