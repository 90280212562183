var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.initialLoading ? _c('div', [_c('v-row', [_c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1)], 1) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.feedbackLoading,
      "pagination": _vm.pagination,
      "title": _vm.title,
      "clickable-row": ""
    }
  }, [_c('v-data-table', {
    attrs: {
      "expanded": _vm.latestExpand,
      "headers": _vm.feedbackHeader,
      "items": _vm.sessionFeedback,
      "disable-sort": "",
      "mobile-breakpoint": "0",
      "hide-default-footer": "",
      "single-expand": ""
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.latestExpand = $event;
      },
      "click:row": function clickRow($event) {
        var _$event$release;
        return _vm.$router.push({
          name: 'release-comment',
          params: {
            app_id: _vm.$route.params.app_id,
            release_id: $event === null || $event === void 0 ? void 0 : (_$event$release = $event.release) === null || _$event$release === void 0 ? void 0 : _$event$release.id,
            comment_id: $event.id
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "text-uppercase font-weight-bold"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.user",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('user-avatar', {
          attrs: {
            "user-detail": item.user
          }
        })];
      }
    }, {
      key: "item.attachments",
      fn: function fn(_ref3) {
        var _item$stats$attachmen, _item$stats;
        var item = _ref3.item;
        return [_c('flex-row', {
          attrs: {
            "gap": "4px",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-attachment")]), _c('span', [_vm._v(_vm._s(_vm._f("formatNumber")((_item$stats$attachmen = item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.attachments) !== null && _item$stats$attachmen !== void 0 ? _item$stats$attachmen : 0)))])], 1)];
      }
    }, {
      key: "item.release",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('release-chip', {
          attrs: {
            "release": item.release
          }
        })];
      }
    }, {
      key: "item.message",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.message ? _c('MaxText', {
          attrs: {
            "text": item.message,
            "max": "150"
          }
        }) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on;
              return [_c('span', _vm._g({}, on), [_c('v-icon', {
                attrs: {
                  "color": _vm.$options.STATUS[item.status].color
                }
              }, [_vm._v(_vm._s(_vm.$options.STATUS[item.status].icon))])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$options.STATUS[item.status].title) + " ")])])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('no-data', {
          attrs: {
            "align-class": "text-left",
            "first-text": "No Comments for This App... Yet!",
            "second-text": "<ul> <li><strong>Team</strong>: Team feedback about the app will appear here.</li> <li><strong>Guests</strong>: Shared the app with external testers? Their feedback shows up here. Remember, you can invite unlimited Guests for feedback in our app.</li> </ul>"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('Modal', {
    attrs: {
      "width": "100%"
    },
    on: {
      "close": function close($event) {
        _vm.singleFeedbackDetailModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('single-feedback-detail', {
          attrs: {
            "feedback-detail": _vm.feedbackDetail
          },
          on: {
            "close": function close($event) {
              _vm.singleFeedbackDetailModal = false;
            },
            "update-attachment": _vm.updateAttachmentPage,
            "confirm-delete-attachment": _vm.onConfirmDeleteAttachment,
            "delete-dialog": _vm.openDeleteDialog,
            "update-feedback": _vm.openUpdateFeedback,
            "update-status": _vm.updateStatus,
            "open-flagging": _vm.openFlagging,
            "feedback-update-dialog": _vm.feedbackUpdateDialog
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.singleFeedbackDetailModal,
      callback: function callback($$v) {
        _vm.singleFeedbackDetailModal = $$v;
      },
      expression: "singleFeedbackDetailModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openUpdateFeedbackForm = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "mt-4 font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Update feedback message ")]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-textarea', _vm._b({
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'max:600',
            expression: "'max:600'"
          }],
          attrs: {
            "auto-grow": "",
            "counter": "600",
            "filled": "",
            "hide-details": "auto",
            "label": "Message",
            "persistent-hint": "",
            "placeholder": "Do you have any feedback for this release?",
            "rows": "4"
          },
          model: {
            value: _vm.formValues.message,
            callback: function callback($$v) {
              _vm.$set(_vm.formValues, "message", $$v);
            },
            expression: "formValues.message"
          }
        }, 'v-textarea', _vm.veeValidate('Message', ''), false))], 1), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "mr-2 text-transform-none",
          attrs: {
            "disabled": _vm.updateLoading,
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.openUpdateFeedbackForm = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.updateLoading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.updateFeedback
          }
        }, [_vm._v(" Update ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openUpdateFeedbackForm,
      callback: function callback($$v) {
        _vm.openUpdateFeedbackForm = $$v;
      },
      expression: "openUpdateFeedbackForm"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openFlaggingConfirmModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "mt-4 font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Confirm Flagging ")]), _c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Are you sure you want to flag this feedback? ")]), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.feedbackLoading,
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.openFlaggingConfirmModal = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.feedbackLoading,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateStatus();
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openFlaggingConfirmModal,
      callback: function callback($$v) {
        _vm.openFlaggingConfirmModal = $$v;
      },
      expression: "openFlaggingConfirmModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }